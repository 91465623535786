import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';

import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as Waves from 'node-waves';

import {CoreMenuService} from '@core/components/core-menu/core-menu.service';
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {CoreConfigService} from '@core/services/config.service';
import {CoreLoadingScreenService} from '@core/services/loading-screen.service';

import {emagazineAdminAcademieUserMenu, publicMenu, userMenu} from 'app/core/components/menu/menu';

import {LocalStorageService} from './core/services/local-storage.service';
import {AuthService} from './core/services/auth.service';
import {ResponsivenessUtil} from './shared/util/responsiveness-util.service';
import {ActivatedRoute} from '@angular/router';
import {LocalizationService} from './core/services/localization.service';
import {PlatformConfigurationService} from './core/services/platform-configuration.service';
import {Role} from './core/models';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {CookieConsentModalComponent} from './features/public/cookie-consent-modal/cookie-consent-modal.component';

const TOKEN = 'token';
const ROLES = 'roles';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  coreConfig: any;
  userMenu: any;
  publicMenu: any;
  emagazineAdminAcademieUserMenu: any;
  defaultLanguage: 'en'; // This language will be used as a fallback when a translation isn't found in the current language
  appLanguage: 'en'; // Set application default language i.e fr
  langObserver;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {Title} _title
   * @param {Renderer2} _renderer
   * @param {ElementRef} _elementRef
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreLoadingScreenService} _coreLoadingScreenService
   * @param {CoreMenuService} _coreMenuService
   * @param {TranslateService} _translateService
   * @param localStorageService
   * @param authService
   * @param responsivenessUtil
   * @param route
   * @param localizationService
   * @param platformConfigurationService
   * @param modalService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _title: Title,
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    public _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private _coreMenuService: CoreMenuService,
    private _translateService: TranslateService,
    private localStorageService: LocalStorageService,
    public authService: AuthService,
    private responsivenessUtil: ResponsivenessUtil,
    private route: ActivatedRoute,
    private localizationService: LocalizationService,
    private platformConfigurationService: PlatformConfigurationService,
    private modalService: NgbModal
  ) {
    // Get the application main menu
    this.userMenu = userMenu;
    this.publicMenu = publicMenu;
    this.emagazineAdminAcademieUserMenu = emagazineAdminAcademieUserMenu;

    // Register the menu to the menu service
    this._coreMenuService.register('user', this.userMenu);
    this._coreMenuService.register('public', this.publicMenu);
    this._coreMenuService.register('emag-admin-acad-user', this.emagazineAdminAcademieUserMenu);

    this.setMenu();

    // Add languages to the translation service
    this._translateService.addLangs(['en', 'nl']);

    // This language will be used as a fallback when a translation isn't found in the current language
    this._translateService.setDefaultLang(localStorage.getItem('language') || 'en');
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'en');
    } else {
      this._translateService.use(localStorage.getItem('language'))
    }

    if (localStorage.getItem('cookie-consent') == undefined) {
      localStorage.setItem('cookie-consent', 'false');
    }

    this.platformConfigurationService.fetchPortalConfiguration();

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Init wave effect (Ripple effect)
    Waves.init();

    this.localStorageService.getRolesChangedSubject().subscribe(() => {
      this.setMenu();
    })


    this.langObserver = new BehaviorSubject<string>('nl');
    this.langObserver.subscribe(res => {
      if (res)
        this.localizationService.setLanguage(res);
    });

    this.route.queryParamMap
      .subscribe((params) => {
          let lang = params.get('lang');
          if (lang)
            this.langObserver.next(lang);
        }
      );


    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;

      // Set application default language.

      // Change application language? Read the ngxTranslate Fix


      // ? OR
      // ? User the current browser lang if available, if undefined use 'en'
      // const browserLang = this._translateService.getBrowserLang();
      // this._translateService.use(browserLang.match(/en|fr|de|pt/) ? browserLang : 'en');

      /**
       * ! Fix : ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      /**
       *
       * Using different language than the default ('en') one i.e French?
       * In this case, you may find the issue where application is not properly translated when your app is initialized.
       *
       * It's due to ngxTranslate module and below is a fix for that.
       * Eventually we will move to the multi language implementation over to the Angular's core language service.
       *
       **/


      /**
       * !Fix: ngxTranslate
       * ----------------------------------------------------------------------------------------------------
       */

      // Layout
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'vertical-layout',
        'vertical-menu-modern',
        'horizontal-layout',
        'horizontal-menu'
      );
      // Add class based on config options
      if (this.coreConfig.layout.type === 'vertical') {
        this._elementRef.nativeElement.classList.add('vertical-layout', 'vertical-menu-modern');
      } else if (this.coreConfig.layout.type === 'horizontal') {
        this._elementRef.nativeElement.classList.add('horizontal-layout', 'horizontal-menu');
      }

      // Navbar
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove(
        'navbar-floating',
        'navbar-static',
        'navbar-sticky',
        'navbar-hidden'
      );

      // Add class based on config options
      if (this.coreConfig.layout.navbar.type === 'navbar-static-top') {
        this._elementRef.nativeElement.classList.add('navbar-static');
      } else if (this.coreConfig.layout.navbar.type === 'fixed-top') {
        this._elementRef.nativeElement.classList.add('navbar-sticky');
      } else if (this.coreConfig.layout.navbar.type === 'floating-nav') {
        this._elementRef.nativeElement.classList.add('navbar-floating');
      } else {
        this._elementRef.nativeElement.classList.add('navbar-hidden');
      }

      // Footer
      //--------

      // Remove default classes first
      this._elementRef.nativeElement.classList.remove('footer-fixed', 'footer-static', 'footer-hidden');

      // Add class based on config options
      if (this.coreConfig.layout.footer.type === 'footer-sticky') {
        this._elementRef.nativeElement.classList.add('footer-fixed');
      } else if (this.coreConfig.layout.footer.type === 'footer-static') {
        this._elementRef.nativeElement.classList.add('footer-static');
      } else {
        this._elementRef.nativeElement.classList.add('footer-hidden');
      }

      // Blank layout
      if (
        this.coreConfig.layout.menu.hidden &&
        this.coreConfig.layout.navbar.hidden &&
        this.coreConfig.layout.footer.hidden
      ) {
        this._elementRef.nativeElement.classList.add('blank-page');
        // ! Fix: Transition issue while coming from blank page
        this._renderer.setAttribute(
          this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
          'style',
          'transition:none'
        );
      } else {
        this._elementRef.nativeElement.classList.remove('blank-page');
        // ! Fix: Transition issue while coming from blank page
        setTimeout(() => {
          this._renderer.setAttribute(
            this._elementRef.nativeElement.getElementsByClassName('app-content')[0],
            'style',
            'transition:300ms ease all'
          );
        }, 0);
        // If navbar hidden
        if (this.coreConfig.layout.navbar.hidden) {
          this._elementRef.nativeElement.classList.add('navbar-hidden');
        }
        // Menu (Vertical menu hidden)
        if (this.coreConfig.layout.menu.hidden) {
          this._renderer.setAttribute(this._elementRef.nativeElement, 'data-col', '1-column');
        } else {
          this._renderer.removeAttribute(this._elementRef.nativeElement, 'data-col');
        }
        // Footer
        if (this.coreConfig.layout.footer.hidden) {
          this._elementRef.nativeElement.classList.add('footer-hidden');
        }
      }

      // Skin Class (Adding to body as it requires highest priority)
      if (this.coreConfig.layout.skin !== '' && this.coreConfig.layout.skin !== undefined) {
        this.document.body.classList.remove('default-layout', 'bordered-layout', 'dark-layout', 'semi-dark-layout');
        this.document.body.classList.add(this.coreConfig.layout.skin + '-layout');
      }
    });

    // Set the application page title
    this._title.setTitle(this.coreConfig.app.appTitle);
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit() {
    this.isCookieConsentGiven();
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  private setMenu(): void {
    if (!this.authService.isLoggedIn() || !this.authService.isAcademie()) {
      this._coreMenuService.setCurrentMenu('public');
    } else {
      if (this.authService.currentUserHasRole(Role.EMAGAZINE_ADMIN) && this.authService.isAcademie()) {
        this._coreMenuService.setCurrentMenu('emag-admin-acad-user');
      } else if (this.authService.isAcademie()) {
        this._coreMenuService.setCurrentMenu('user');
      } else {
        this._coreMenuService.setCurrentMenu('public')
      }
    }
  }

  setIsMobile() {
    this.responsivenessUtil.setIsScreenSize(window.innerWidth);
  }

  isCookieConsentGiven() {
    if (!localStorage.getItem('cookie-consent') || localStorage.getItem('cookie-consent') === 'false') {
      const ngbModalOptions: NgbModalOptions = {
        size: 'md',
        centered: true,
        backdrop: 'static',
        keyboard: false,
      };
      setTimeout(() => {
        this.modalService.open(CookieConsentModalComponent, ngbModalOptions);
      }, 1000);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setIsMobile();
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(e) {
    const internalUrls = this.platformConfigurationService.getAllInternalUrls();
    if (!internalUrls.includes(e.origin) || e.data === undefined || e.data.type === 'webpackOk') {
      return;
    }
    const payload = JSON.parse(e.data);
    if (payload.key === 'delete-authorization') {
      localStorage.removeItem(ROLES);
      localStorage.removeItem(TOKEN);
    } else {
      localStorage.setItem(payload.key, payload.data);
    }
  }
}

import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';

import {CustomBreakPointsProvider} from 'app/core/layouts/custom-breakpoints';
import {VerticalLayoutModule} from 'app/core/layouts/vertical/vertical-layout.module';

@NgModule({
  imports: [FlexLayoutModule.withConfig({ disableDefaultBps: true }), VerticalLayoutModule ],
  providers: [CustomBreakPointsProvider],
  exports: [VerticalLayoutModule]
})
export class LayoutModule {}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';
import {AlertService} from '../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {PlatformConfigurationService} from '../services/platform-configuration.service';


@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private _router: Router, private _authService: AuthService, private alertService: AlertService,
              private translate: TranslateService, private platformConfigurationService: PlatformConfigurationService,) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (err.status === 401 && this._router.url !== '/login') {
          this._authService.logout();
          this.platformConfigurationService.subscribeForPortalConfiguration().subscribe(configuration => {
            window.location.href = configuration.platformUrl + '/login-expired'
          })
        }
        if ([500, 501, 502, 503, 504].includes(err.status)) {
          this._router.navigate(['miscellaneous/maintenance']);
        }
        const error = err.error.translationKey || err.statusText;
        const errorTranslation = this.translate.instant(error);
        this.alertService.error(errorTranslation, {closeButton: true, autoClose: false, keepAfterRouteChange: false});
        return throwError(error);
      })
    );
  }
}

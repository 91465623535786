import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {CoreCommonModule} from '@core/common.module';

import {BreadcrumbModule} from 'app/core/components/content-header/breadcrumb/breadcrumb.module';
import {ContentHeaderComponent} from 'app/core/components/content-header/content-header.component';
import {AlertModule} from '../alert/alert.module';

@NgModule({
  declarations: [ContentHeaderComponent],
    imports: [CommonModule, RouterModule, CoreCommonModule, BreadcrumbModule, NgbModule, AlertModule],
  exports: [ContentHeaderComponent]
})
export class ContentHeaderModule {}

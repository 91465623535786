import {Injectable} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmModalComponent} from '../../shared/components/confirm-modal/confirm-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  modalRef: any;

  constructor(public modalService: NgbModal, private translate: TranslateService) { }

  public confirm(
    title: string = 'ARE_YOU_SURE',
    message: string,
    btnConfirmText: string = 'YES',
    btnCancelText: string = 'NO_GO_BACK',
    dialogSize: 'sm' | 'lg' = 'sm'): any {
    this.modalRef = this.modalService.open(ConfirmModalComponent,
      {size: dialogSize, backdrop: 'static', keyboard: false}
    );
    this.modalRef.componentInstance.title = this.translate.instant(title);
    this.modalRef.componentInstance.message = this.translate.instant(message);
    this.modalRef.componentInstance.btnOkText = this.translate.instant(btnConfirmText);
    this.modalRef.componentInstance.btnCancelText = this.translate.instant(btnCancelText);
    return this.modalRef.result;
  }

  openCancelConfirmationDialog(activeModal: NgbActiveModal) {
    this.confirm(undefined,
      'EXIT_WITHOUT_CHANGES_CONFIRMATION')
      .then((res) => {
        if (res)
          activeModal.close(false)
      });
  }

  confirmCancelApplicationDialog() {
    let subject = new Subject<boolean>();
    this.confirm(undefined, 'CANCEL_APPLICATION_ACCOUNT_IS_STILL_CHARGED', undefined, undefined, 'lg').then((res) => {
      subject.next(res);
    });
    return subject;
  }

  confirmPaymentDialog() {
    let subject = new Subject<boolean>();
    this.confirm(undefined, 'PROCESS_PAYMENT_DIALOG', undefined, undefined, 'sm').then((res) => {
      subject.next(res);
    });
    return subject;
  }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

const TOKEN = 'token';
const ROLES = 'roles';
const LANGUAGE = 'language';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public rolesChanged: Subject<string[]> = new Subject<any>();

  constructor() {
  }

  clear() {
    localStorage.clear();
  }

  clearUserData() {
    localStorage.removeItem(ROLES);
    localStorage.removeItem(TOKEN);
  }

  containsToken(): boolean {
    return this.contains(TOKEN);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN);
  }

  getRoles(): string[] {
    return this.get(ROLES);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN, token);
  }

  setRoles(roles: string[]) {
    localStorage.setItem(ROLES, JSON.stringify(roles));
    this.emitRoleChangeEvent();
  }

  setLanguage(languageId) {
    localStorage.setItem(LANGUAGE, languageId);
  }

  getLanguage(): string {
    return localStorage.getItem(LANGUAGE);
  }

  private contains(key: string) {
    return !!localStorage.getItem(key);
  }

  private get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  getRolesChangedSubject(): Subject<any> {
    return this.rolesChanged;
  }

  private emitRoleChangeEvent() {
    this.rolesChanged.next();
  }
}

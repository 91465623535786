
<!-- vertical-layout -->
<vertical-layout isLoggedIn="{{authService.isLoggedIn()}}"></vertical-layout>
<!-- / vertical-layout -->

<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->

import {Injectable} from '@angular/core';

const maxXSWidth = 576;
const maxSWidth = 768;
const maxMdWidth = 992;
const maxLgWidth = 1200;
const maxXlWidth = 1400;

@Injectable({
  providedIn: 'root'
})

// works same as bootstrap responsive grids
export class ResponsivenessUtil {

  isXs = window.innerWidth < maxXSWidth;
  isS = window.innerWidth >= maxXSWidth;
  isMd = window.innerWidth >= maxSWidth;
  isLg = window.innerWidth >= maxMdWidth;
  isXl = window.innerWidth >= maxLgWidth;
  isXxl = window.innerWidth >= maxXlWidth;


  setIsScreenSize(screenWidth) {
    this.isXs = screenWidth <= maxXSWidth;
    this.isS = screenWidth >= maxXSWidth;
    this.isMd = screenWidth >= maxSWidth;
    this.isLg = screenWidth >= maxMdWidth;
    this.isXl = screenWidth >= maxLgWidth;
    this.isXxl = screenWidth >= maxXlWidth;
  }
}

import {CoreMenu} from '@core/types'

export const userMenu: CoreMenu[] = [
  {
    id: 'courses',
    title: 'Courses',
    translate: 'COURSES',
    type: 'item',
    icon: 'book-open',
    url: 'courses/all'
  },
  {
    id: 'posts',
    title: 'Posts',
    translate: 'POSTS',
    type: 'section',
    children: [
      {
        id: 'general-posts',
        title: 'General posts',
        translate: 'GENERAL_POSTS',
        type: 'item',
        icon: 'bell',
        url: 'posts/general-posts'
      },
      {
        id: 'course-news',
        title: 'Course News',
        translate: 'COURSE_NEWS',
        type: 'item',
        icon: 'book',
        url: 'posts/course-news'
      }
    ]
  }
]

export const emagazineAdminAcademieUserMenu: CoreMenu[] = [
  {
    id: 'general',
    title: 'General admin',
    type: 'section',
    translate: 'GENERAL_ADMIN',
    children: [
      {
        id: 'user-management',
        title: 'User Management',
        translate: 'USER_MANAGEMENT',
        icon: 'users',
        type: 'item',
        url: 'administration/user-management'
      }
    ]
  },
  {
    id: 'magazine-admin',
    title: 'eMag admin',
    type: 'section',
    translate: 'MAGAZINE_ADMIN',
    children: [
      {
        id: 'issues',
        title: 'Issues',
        translate: 'ISSUES',
        type: 'item',
        icon: 'book-open',
        url: '/magazine/issues'
      },
      {
        id: 'articles',
        title: 'Articles',
        translate: 'ARTICLES',
        type: 'item',
        icon: 'file',
        url: '/magazine/articles'
      }
    ]
  },
  {
    id: 'courses',
    title: 'Courses',
    translate: 'COURSES',
    type: 'section',
    children: [
      {
        id: 'courses',
        title: 'Courses',
        translate: 'COURSES',
        type: 'item',
        icon: 'book-open',
        url: 'courses/all'
      }
    ]
  },
  {
    id: 'posts',
    title: 'Posts',
    translate: 'POSTS',
    type: 'section',
    children: [
      {
        id: 'general-posts',
        title: 'General Posts',
        translate: 'GENERAL_POSTS',
        type: 'item',
        icon: 'bell',
        url: 'posts/general-posts'
      },
      {
        id: 'course-news',
        title: 'Course News',
        translate: 'COURSE_NEWS',
        type: 'item',
        icon: 'book',
        url: 'posts/course-news'
      }
    ]
  }
]

export const publicMenu: CoreMenu[] = [
  {
    id: 'courses',
    title: 'Courses',
    translate: 'COURSES',
    type: 'item',
    icon: 'book-open',
    url: 'public'
  },
  {
    id: 'posts',
    title: 'Posts',
    translate: 'POSTS',
    type: 'section',
    children: [
      {
        id: 'general-posts',
        title: 'General Posts',
        translate: 'GENERAL_POSTS',
        type: 'item',
        icon: 'bell',
        url: 'posts/general-posts'
      },
      {
        id: 'course-news',
        title: 'Course News',
        translate: 'COURSE_NEWS',
        type: 'item',
        icon: 'book',
        url: 'posts/course-news'
      }
    ]
  }
]

import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from './local-storage.service';
import {LoginModel} from '../models/auth/login';
import {Observable} from 'rxjs';
import {TokenModel} from '../models/auth/token';
import {CookieService} from 'ngx-cookie-service';
import {Role} from '../models';
import {PlatformConfigurationService} from './platform-configuration.service';

const BASE_URL = '/api/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginOccurred = new EventEmitter<any>();

  constructor(private http: HttpClient,
              private router: Router,
              private localStorageService: LocalStorageService,
              private cookieService: CookieService,
              private platformConfigurationService: PlatformConfigurationService
  ) {
  }

  login(loginData: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(BASE_URL, loginData);
  }

  logout() {
    if (this.getTokenIssuer() != 'lms-application') {
      window.location.href = window.location.origin + '/api/sso-logout';
    }
    this.localStorageService.clearUserData();
    this.cookieService.deleteAll();
  }

  getTokenIssuer(): string {
    return this?.decodeToken()?.iss;
  }

  saveUserInfo(token: string, roles: string[]) {
    this.localStorageService.setToken(token);
    this.localStorageService.setRoles(roles);
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  getToken(): string {
    return this.localStorageService.getToken();
  }

  getUsername(): string {
    const email = this?.decodeToken()?.vo_email;
    if (email != null)
      return email;
    const sub = this?.decodeToken()?.sub;
    if (sub != null)
      return sub;
    return ''
  }

  getRoles(): string[] {
    return this.localStorageService.getRoles();
  }

  isAdmin(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles().includes(Role.SUPERADMIN_SCHAARBEEK);
  }

  isApprover(): boolean {
    if (!this.isLoggedIn()) return false;
    return this.getRoles().includes(Role.APPROVER_FREE_CLB);
  }

  isAcademie(): boolean {
    if (!this.isLoggedIn()) return false;
    let hasRole = false;
    this.platformConfigurationService.getAcademieRoles().forEach(role => {
      if (this.getRoles()?.includes(role)) hasRole = true;
    })
    return hasRole;
  }

  isMagazine(): boolean {
    if (!this.isLoggedIn()) return false;
    let hasRole = false;
    this.platformConfigurationService.getMagazineRoles().forEach(role => {
      if (this.getRoles().includes(role)) hasRole = true;
    })
    return hasRole;
  }

  isExternalUser(): boolean {
    if(!this.isLoggedIn() || this.getRoles().includes(Role.PARTICIPANT_FREE)
      || this.getRoles().includes(Role.APPROVER_FREE_CLB) || this.getRoles().includes(Role.SUPERADMIN_SCHAARBEEK))
      return false;
    return this.getRoles().includes(Role.CLB_EXTERNAL_PARTICIPANT);
  }

  currentUserHasRole(role: string): boolean {
    if (this.localStorageService.getRoles()) {
      return this.getRoles().includes(Role[role]);
    }
    return true;
  }

  decodeToken(): any {
    if (this.getToken() == null) return null
    const jwtData = this.getToken().split('.')[1];
    const jwtFormattedData = jwtData ? jwtData.replace('-', '+').replace('_', '/') : null;
    const decodedJwtJsonData = atob(jwtFormattedData);
    return JSON.parse(decodedJwtJsonData);
  }

  getLoginOccurredEmitter(): EventEmitter<any> {
    return this.loginOccurred
  }
}

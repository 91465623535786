<!-- app-content-header start -->
<div class="">
  <app-alert id="default-alert"></app-alert>
</div>
<ng-container *ngIf="contentHeader">
  <div class="content-header row mb-sm-3 mb-lg-0 ml-0" style="height: 4em;">
    <div class="content-header-left ml-md-3 ml-1">
      <div class="row breadcrumbs-top">
        <div class="d-flex">
          <h2 class="content-header-title float-left mb-0">
            {{ contentHeader.headerTitle }}
          </h2>
          <!-- app-breadcrumb component -->
          <app-breadcrumb [breadcrumb]="contentHeader.breadcrumb"></app-breadcrumb>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- app-content-header end -->

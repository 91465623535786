import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-input-filter-dropdown',
  templateUrl: './input-filter-dropdown.component.html',
  encapsulation: ViewEncapsulation.None
})
export class InputFilterDropdownComponent implements OnChanges {

  @Input()
  selectedItemInternal;
  @Input()
  title: string = '';
  @Input()
  items: any[];
  @Input()
  displayField: string = ''; //use this field when working with list of objects to specify field to show in multiple select
  @Input()
  placeholder = '';
  @Input()
  hasSetWidth = false;
  @Input()
  customStyle: any;
  @Input()
  hasError = false;

  @Output()
  public itemSelect: Observable<any>;
  @Output()
  selectedItem = new EventEmitter<any>();
  @Output()
  searchTerm = new EventEmitter<string>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items)
      this.itemSelect = of(changes.items.currentValue).pipe(delay(1));
  }

  getWidth() {
    let max_length = 0;
    for (let value of this.items) {
      let showValue = this.displayField ? value[this.displayField] : value;
      if (showValue?.length > max_length) {
        max_length = showValue?.length
      }
    }
    if (this.placeholder?.length > max_length)
      max_length = this.placeholder?.length;
    return max_length;
  }

  getMinWidth() {
    return window.innerWidth < 600 ? 0 : this.getWidth() * 8 + 50;
  }

  selectedChanged($event: any) {
    this.selectedItem.emit($event);
  }

  getMaxWidth() {
    return window.innerWidth < 600 ? 500 : this.getWidth() * 8 + 50;
  }

  onSearch($event: { term: string; items: any[] }) {
    this.searchTerm.emit($event.term);
  }
}

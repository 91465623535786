<div id="cookieConsentModal">
  <div class="modal-header modal-align-center">
    <div>
      <h4 class="modal-title text-primary">
        <span class="mr-1">{{'COOKIE_CONSENT_MODAL_TITLE'|translate}}</span>
      </h4>
    </div>
  </div>
  <div class="modal-body modal-align-center" tabindex="0">
    <p class="cookie-consent-text-align-center">{{'COOKIE_CONSENT_MODAL_TEXT'|translate}}</p>
  </div>
  <div class="modal-footer modal-align-center">
    <button (click)="onAccept()" class="btn btn-outline-primary btn-transition" rippleEffect tabindex="0" type="button">
      <div *ngIf="shouldShowLoading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="ml-25 align-middle"></span>
      </div>
      <div *ngIf="!shouldShowLoading" >
        {{'COOKIE_CONSENT_MODAL_ACCEPT'|translate}}
      </div>
    </button>
  </div>
</div>
<iframe hidden></iframe>
<iframe hidden></iframe>

import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  private selectedLanguage;
  private languageOptions = [
    {
      'title': 'English',
      'id': 'en'
    },
    {
      'title': 'Dutch',
      'id': 'nl'
    }];

  public shouldShowLanguagePicker = false;

  constructor(private _translateService: TranslateService,
              private _localStorageService: LocalStorageService) {
  }

  setDutchAsDefaultLanguage() {
    this._translateService.use('nl');
    this._localStorageService.setLanguage('nl');
  }

  setLanguage(language?): void {
    if (language === this._localStorageService.getLanguage() || !language) {
      return;
    }
    if (!language.hasOwnProperty('id')) {
      language = this.getLanguageById(language);
    }
    if (language == null) {
      language = this.languageOptions.filter(lang => lang.id === this._localStorageService.getLanguage())[0]
    }
    if (language.length === 0 || (this.selectedLanguage && this.selectedLanguage === language)) {
      return;
    }
    this._translateService.use(language.id);
    this._localStorageService.setLanguage(language.id);
    if (this.selectedLanguage) {
      this.selectedLanguage = language;
      window.location.reload();
    } else {
      this.selectedLanguage = language;
    }
  }

  private getLanguageById(id) {
    return this.languageOptions.filter(lang => lang.id === id)[0];
  }

  getSelectedLanguage() {
    return this.languageOptions.filter(lang => lang.id === this._localStorageService.getLanguage())[0];
  }

  getLanguageOptions() {
    return this.languageOptions;
  }
}

<div>
  <div class="modal-header">
    <h4 class="modal-title text-primary">{{ title }}</h4>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button (click)="decline()" class="btn btn-outline-primary" ngbAutofocus type="button">
      {{ btnCancelText }}
    </button>
    <button (click)="accept()" class="btn btn-secondary" type="button">
      {{ btnOkText }}
    </button>
  </div>
</div>

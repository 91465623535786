import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';

import {CoreCommonModule} from '@core/common.module';
import {ContentHeaderModule} from 'app/core/components/content-header/content-header.module';

import {DropdownComponent} from './components/dropdown/dropdown.component';
import {RouterModule, Routes} from '@angular/router';
import {MultipleSelectComponent} from './components/multipleselect/multiple-select/multiple-select.component';
import {DatepickerComponent} from './components/datepicker/datepicker.component';
import {TranslateModule} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {ConfirmModalComponent} from './components/confirm-modal/confirm-modal.component';
import {ConfirmDialogService} from '../core/services/confirm-dialog.service';
import {TwoDigitDecimalPositiveNumberDirective} from './directives/two-digit-decimal-positive-number.directive';
import {InputFilterDropdownComponent} from './components/input-filter-dropdown/input-filter-dropdown.component';

const routes: Routes = [];

@NgModule({
  declarations: [
    DropdownComponent,
    MultipleSelectComponent,
    DatepickerComponent,
    ConfirmModalComponent,
    TwoDigitDecimalPositiveNumberDirective,
    InputFilterDropdownComponent
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    RouterModule.forChild(routes),
    TranslateModule
  ],
  exports: [
    DropdownComponent,
    MultipleSelectComponent,
    DatepickerComponent,
    TranslateModule,
    TwoDigitDecimalPositiveNumberDirective,
    InputFilterDropdownComponent
  ],

  providers: [CookieService, ConfirmDialogService]
})
export class SharedModule {
}

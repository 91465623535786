import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-multiple-select',
  templateUrl: './multiple-select.component.html',
  encapsulation: ViewEncapsulation.None
})
export class MultipleSelectComponent implements OnChanges {
  public selectMulti: Observable<any[]>;

  @Input()
  selectedItemsInternal = [];
  @Input()
  title: string = '';
  @Input()
  items: any[];
  @Input()
  displayField: string = ''; //use this field when working with list of objects to specify field to show in multiple select
  @Output()
  selectedItems = new EventEmitter<any[]>();
  @Input()
  placeholder = '';
  @Input()
  hasSetWidth = false;
  @Input()
  customStyle: any;
  @Input()
  hasError = false;
  @Input()
  submitted = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items)
      this.selectMulti = of(changes.items.currentValue).pipe(delay(1));
  }

  getWidth() {
    let max_length = 0;
    for (let value of this.items) {
      let showValue = this.displayField ? value[this.displayField] : value;
      if (showValue?.length > max_length) {
        max_length = showValue?.length
      }
    }
    if (this.placeholder?.length > max_length)
      max_length = this.placeholder?.length;
    return max_length;
  }

  getMinWidth() {
    return window.innerWidth < 600 ? 0 : this.getWidth() + 10;
  }

  selectedChanged($event: any) {
    this.selectedItems.emit($event);
  }

  getMaxWidth() {
    return window.innerWidth < 600 ? 500 : this.getWidth() * 8 + 50;
  }
}

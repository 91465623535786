import {HttpRequest} from '@angular/common/http';

export class TokenInterceptorUtil {

  public static setHeaders(request: HttpRequest<any>, token: string) {
    request = TokenInterceptorUtil.setAuthorizationHeader(request, token);
    return request;
  }

  private static setAuthorizationHeader(request: HttpRequest<any>, token: string) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return request;
  }
}

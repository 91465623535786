<!-- Not authorized-->
<div class="misc-wrapper">
  <a class="brand-logo" href="javascript:void(0);">
    <img alt="brand-logo" height="28" src="{{ coreConfig.app.appLogoImage }}"/>
    <h2 class="brand-text text-primary ml-1">Vuexy</h2></a
  >
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">You are not authorized! 🔐</h2>
      <p class="mb-2">
        The Webtrends Marketing Lab website in IIS uses the default IUSR account credentials to access the web pages it
        serves.
      </p>
      <a class="btn btn-primary mb-1 btn-sm-block" rippleEffect routerLink="/"
      >Back to home</a
      ><img
      [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/not-authorized-dark.svg'
            : 'assets/images/pages/not-authorized.svg'
        "
      alt="Not authorized page"
      class="img-fluid"
    />
    </div>
  </div>
</div>
<!-- / Not authorized-->

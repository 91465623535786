<div class="{{verticalAlign? cssClasses : cssClasses + ' row align-items-center ml-0'}}">
  <label *ngIf="title" class="{{verticalAlign?'': 'mr-2'}}">{{title|translate}}</label>
  <div>
    <div ngbDropdown >
      <button
        class="btn btn-{{dropdownStyle}} {{width}} custom-form-component {{hasError?'invisible-error': ''}}"
        [disabled]="disabled"
        id="dropdownMenuButton"
        ngbDropdownToggle
        rippleEffect
        type="button"
        #myInput
        [tabIndex]="tabAccess? 0: -1">
        {{selectedItemInternal | translate}}
      </button>
      <div aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
        <a (click)="setSelectedOption(item)" *ngFor="let item of items" href="javascript:void(0)"
           ngbDropdownItem>{{displayField ? (item[displayField]|translate) : (item |translate)}}
        </a>
      </div>
    </div>
  </div>
</div>

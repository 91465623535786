<!-- Menu header -->
<div class="navbar-header">
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item ">
      <!-- App Branding -->
      <a [href]="resolveHomeRoute()" class="navbar-brand mb-2">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo"/>
        </span>
        <div class="title-container">
          <div class="row">
            <h5 class="brand-text mb-0 ml-0 pl-0">Caleidoscoop</h5>
          </div>
          <div class="row">
            <h5 class="brand-text mb-0 ml-0 pl-0">Academie</h5>
          </div>
        </div>
      </a>
    </li>

    <!-- Menu Toggler -->
    <li class="nav-item nav-toggle p-0">
      <a class="nav-link modern-nav-toggle d-none d-xl-block pt-1 pl-1" (click)="toggleSidebarCollapsible()">
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li>
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>
<br/>
<br/>
<!-- Main menu -->
<div (scroll)="onSidebarScroll()" [perfectScrollbar] class="main-menu-content mt-2">
  <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div>
<!--/ Main menu -->

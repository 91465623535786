import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CoreCommonModule} from '@core/common.module';
import {NotAuthorizedComponent} from './not-authorized.component';


// routing
const routes: Routes = [
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent
  },
];

@NgModule({
  declarations: [NotAuthorizedComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CoreCommonModule]
})
export class NotAuthorizedModule {
}
